import {
  BulkInsertPOSRequestInterface,
  UpdateStatusAndSubscriptionPOSRequestInterface,
  UpdatePOSRequestInterface,
} from '@/data/payload/contracts/POSRequest'

export class BulkInsertPOSRequest implements BulkInsertPOSRequestInterface {
  public file?: Blob
  public actorBy?: string

  constructor(file?: Blob, actorBy?: string) {
    this.file = file
    this.actorBy = actorBy
  }

  public toPayload() {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('file', this.file as Blob)
    if (this.actorBy) {
      data.append('actor_by', this.actorBy)
    }

    return data
  }
}

export class UpdateStatusAndSubscriptionPOSRequest
  implements UpdateStatusAndSubscriptionPOSRequestInterface {
  public status?: string
  public isSubscription?: boolean
  public actorBy?: string

  constructor(status?: string, isSubscription?: boolean, actorBy?: string) {
    this.status = status
    this.isSubscription = isSubscription
    this.actorBy = actorBy
  }

  public toPayload() {
    const data = {
      agent_status: this.status,
      is_subscription: this.isSubscription,
      actor_by: this.actorBy,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdatePOSRequest implements UpdatePOSRequestInterface {
  public subdistrictId?: number
  public letterCode?: string
  public address?: string
  public latitude?: number
  public longitude?: number
  public phone?: string
  public actorBy?: string

  constructor(
    subdistrictId?: number,
    letterCode?: string,
    address?: string,
    latitude?: number,
    longitude?: number,
    phone?: string,
    actorBy?: string
  ) {
    this.subdistrictId = subdistrictId
    this.letterCode = letterCode
    this.address = address
    this.latitude = latitude
    this.longitude = longitude
    this.phone = phone
    this.actorBy = actorBy
  }

  public toPayload() {
    const data = {
      subdistrict_id: this.subdistrictId,
      letter_code: this.letterCode,
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude,
      phone: this.phone,
      actor_by: this.actorBy,
    }

    return JSON.stringify({
      ...data,
    })
  }
}
