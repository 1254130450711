import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import {
  BulkInsertPOSRequest,
  UpdateStatusAndSubscriptionPOSRequest,
  UpdatePOSRequest,
} from '@/data/payload/api/POSRequest'
import {
  EventBus,
  EventBusConstants,
  LocalStorage,
  Utils,
} from '@/app/infrastructures/misc'
import store from '@/app/ui/store'
import { POSPresenter } from '../presenters/POSPresenter'
import { Pagination } from '@/domain/entities/Pagination'
import { Courier } from '@/domain/entities/Courier'
import { POS, POSes, Subdistrict } from '@/domain/entities/POS'

export interface POSState {
  isLoading: boolean
  isLoadingSubdistrict: boolean
  isDownloading: boolean
  isUploading: boolean
  posCourier: Courier[]
  posData: POS[]
  posDetail: POS
  paginationData: Pagination
  subdistricts: Subdistrict[]
}

@Module({ namespaced: true, store, name: 'pos', dynamic: true })
class POSControlller extends VuexModule implements POSState {
  private presenter: POSPresenter = container.resolve(POSPresenter)
  public isLoading = false
  public isLoadingSubdistrict = false
  public isDownloading = false
  public isUploading = false
  public posCourier = [new Courier()]
  public posData = [new POS()]
  public posDetail = new POS()
  public paginationData = new Pagination()
  public subdistricts = [new Subdistrict()]
  public isGuest = Utils.isGuest()

  @Action({ rawError: true })
  public getCourierList(agentId: string) {
    this.setLoading(true)

    this.presenter
      .getCourierByAgentId(agentId)
      .then(res => {
        this.setCourierData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch POS Courier Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public bulkInsert(file: File) {
    this.setUploading(true)

    const userIdentity = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    this.presenter
      .bulkInsert(new BulkInsertPOSRequest(file, userIdentity))
      .then(res => {
        Vue.notify({
          title: 'Upload Excel',
          text: 'Bulk Update or Insert Success',
          type: 'success',
          duration: 3000,
        })
        EventBus.$emit(EventBusConstants.UPLOAD_BULK_UPDATE_POST_SUCCESS, res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Upload Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setUploading(false)
      })
  }

  @Action({ rawError: true })
  public getPOSDetail(agentId: string) {
    this.setLoading(true)

    this.presenter
      .get(agentId)
      .then(res => {
        this.setPOSDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch POS Detail Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFile(params: Record<string, any>) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then(res => {
        EventBus.$emit(EventBusConstants.DOWNLOAD_POS_READY, {
          downloadUrl: res,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getPOSData(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setPOSData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Mitra POS Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public findSubdistrict(params: Record<string, any>) {
    this.setSubdistrictLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .findSubdistrict(formattedParams)
      .then(res => {
        this.setSubdistrictData(res)
      })
      .catch(error => {
        console.warn('ERROR', error)
      })
      .finally(() => {
        this.setSubdistrictLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePOSStatusAndSubscription(form: {
    agentId: string
    status: string
    isSubscription: boolean
  }) {
    this.setLoading(true)

    this.presenter
      .updateStatusAndSubscription(
        form.agentId,
        new UpdateStatusAndSubscriptionPOSRequest(
          form.status,
          form.isSubscription,
          LocalStorage.getLocalStorage(LocalStorage.LOGIN_IDENTITY, true)
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_POS_SUCCESS, {
          id: form.agentId,
        })
      })
      .catch(error => {
        this.getPOSDetail(form.agentId)
        this.getCourierList(form.agentId)
        Vue.notify({
          title: 'Update POS Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePOS(form: {
    agentId: string
    subdistrictId: number
    letterCode: string
    address: string
    latitude: number
    longitude: number
    phone: string
  }) {
    this.setLoading(true)

    this.presenter
      .update(
        form.agentId,
        new UpdatePOSRequest(
          form.subdistrictId,
          form.letterCode,
          form.address,
          parseFloat(form.latitude.toString()),
          parseFloat(form.longitude.toString()),
          Utils.countryIndonesiaPhoneNumber(form.phone, true),
          LocalStorage.getLocalStorage(LocalStorage.LOGIN_IDENTITY, true)
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_POS_SUCCESS, {
          id: form.agentId,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update POS Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setDownloading(bool: boolean) {
    this.isDownloading = bool
  }

  @Mutation
  private setUploading(bool: boolean) {
    this.isUploading = bool
  }

  @Mutation
  private setSubdistrictLoading(bool: boolean) {
    this.isLoadingSubdistrict = bool
  }

  @Mutation
  private setCourierData(couriers: Courier[]) {
    this.posCourier = couriers
  }

  @Mutation
  private setPOSDetail(pos: POS) {
    this.posDetail = pos
  }

  @Mutation
  private setPOSData(poses: POSes) {
    this.posData = poses.data as POS[]
    this.paginationData = poses.pagination as Pagination
  }

  @Mutation
  private setSubdistrictData(subdistrict: Subdistrict[]) {
    this.subdistricts = subdistrict
  }

  @Mutation
  public clearPOSDetail() {
    this.posDetail = new POS()
  }
}

export default getModule(POSControlller)
