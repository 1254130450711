


















































































































































































































































































































































































































import { toJpeg } from 'html-to-image'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
  alpha,
  numeric,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators'
import { POS } from '@/domain/entities/POS'
import controller from '@/app/ui/controllers/POSController'
import Modal from '@/app/ui/components/Modal/index.vue'
import PreviewOverlay from '@/app/ui/components/PreviewOverlay/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import ImageIcon from '@/app/ui/assets/menu_icon_banner.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import POSTextInput from '../components/POSTextInput/index.vue'
import POSImage from '../components/POSImage/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  MAX_LATITUDE,
  MAX_LONGITUDE,
  MIN_LATITUDE,
  MIN_LONGITUDE,
} from '@/app/infrastructures/misc/Constants/validation'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    Toggle,
    DataTable,
    POSTextInput,
    PreviewOverlay,
    EditIcon,
    DownloadIcon,
    POSImage,
    ImageIcon,
    LoadingOverlay,
  },
})
export default class POSDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationStatusModal = false
  confirmationSubscriptionModal = false
  confirmationUpdateModal = false
  successModal = false
  isPOSActive = false
  isFavorite = false
  isPreviewModal = false
  isLoadingQRPreview = false
  isLoadingQRPDF = false
  headers = ['Courier ID', 'No HP Courier', 'Courier Status']
  form = {
    subdistrictId: 0,
    letterCode: '',
    address: '',
    phone: '',
    latitude: 0,
    longitude: 0,
  }
  hasChanged = {
    subdistrictId: false,
    letterCode: false,
    address: false,
    phone: false,
    latitude: false,
    longitude: false,
  }
  renderUrl = ''
  renderElement: HTMLElement | null = null
  imageContentElementId = `image-content-${
    this.posDetail.id
  }-${window.performance.now().toString()}`

  created(): void {
    controller.clearPOSDetail()
    controller.getPOSDetail(this.$route.params.id)
    controller.getCourierList(this.$route.params.id)

    EventBus.$on(EventBusConstants.UPDATE_POS_SUCCESS, () => {
      this.successModal = true
      this.$router.replace({ query: {} })
      this.controller.getPOSDetail(this.$route.params.id)
    })
  }

  private renderImage() {
    this.renderElement = document.getElementById(this.imageContentElementId)

    if (this.renderElement) {
      if (!this.renderUrl) {
        this.isLoadingQRPreview = true
        toJpeg(this.renderElement, {
          quality: 0.95,
          pixelRatio: 5,
        })
          .then((dataUrl) => {
            this.renderUrl = dataUrl
          })
          .catch((err) => {
            this.$notify({
              title: 'Preview QR Failed',
              text: err,
              type: 'error',
              duration: 5000,
            })
          })
          .finally(() => {
            this.isLoadingQRPreview = false
          })
      }
    } else {
      this.$notify({
        title: 'Render QR Issue',
        text: 'Please refresh the page to try re-render QR Image',
        type: 'warn',
        duration: 5000,
      })
    }
  }

  @Validations()
  validations(): Record<string, unknown> {
    const validate = {
      form: {
        subdistrictId: { required, numeric, minValue: minValue(1) },
        letterCode: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(3),
          alpha,
        },
        address: { required, minLength: minLength(10) },
        phone: { required },
        latitude: {
          required,
          minValue: minValue(MIN_LATITUDE),
          maxValue: maxValue(MAX_LATITUDE),
        },
        longitude: {
          required,
          minValue: minValue(MIN_LONGITUDE),
          maxValue: maxValue(MAX_LONGITUDE),
        },
      },
    }

    return validate
  }

  @Watch('posDetail')
  onPOSDetailChanged(pos: POS): void {
    this.isPOSActive = pos.status === 'ACTIVE' ? true : false
    this.isFavorite = pos.subscriptionStatus || false

    const latlong = pos.location?.split(',')
    this.form = {
      subdistrictId: pos.subdistrictId || 0,
      letterCode: pos.letterCode || '',
      address: pos.address || '',
      phone: pos.phone || '',
      latitude: Number(latlong ? latlong[0] : 0),
      longitude: Number(latlong ? latlong[1] : 0),
    }
  }

  get courierDataTable(): (string | undefined)[][] {
    return controller.posCourier.map((courier) => [
      courier.id,
      courier.phone,
      courier.status,
    ])
  }

  get posDetail(): POS {
    return controller.posDetail
  }

  get subscriptionName(): string {
    return this.isFavorite ? 'Favorit' : 'Reguler'
  }

  private downloadQRCode() {
    this.isLoadingQRPDF = true
    var pdf = new jsPDF('p', 'pt', 'a4')
    var width = pdf.internal.pageSize.getWidth()
    var height = pdf.internal.pageSize.getHeight()

    pdf.addImage(this.renderUrl, 'JPEG', 0, 0, width, height)
    pdf.save('POS-' + this.posDetail.code + '.pdf')

    this.isLoadingQRPDF = false
  }

  private getPOSStatus(bool: boolean): string {
    return bool ? 'ACTIVE' : 'SUSPENDED'
  }

  private onToggleStatus(event: { target: { checked: boolean } }) {
    this.isPOSActive = event.target.checked
    this.confirmationStatusModal = true
  }

  private onChangeSubscription(bool: boolean) {
    if (!this.isGuest) {
      this.isFavorite = bool
      this.confirmationSubscriptionModal = true
    }
  }

  private sendUpdate() {
    this.setAllHasChanged()
    if (!this.$v.form.$invalid) {
      this.controller.updatePOS({
        agentId: this.$route.params.id,
        ...this.form,
        subdistrictId: Number(this.form.subdistrictId),
      })
    } else {
      Vue.notify({
        title: 'Update POS Failed',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
    this.confirmationUpdateModal = false
  }

  private sendUpdateStatus() {
    this.controller.updatePOSStatusAndSubscription({
      agentId: this.$route.params.id,
      status: this.getPOSStatus(this.isPOSActive),
      isSubscription: this.isFavorite,
    })
    this.confirmationStatusModal = false
    this.confirmationSubscriptionModal = false
  }

  private onCloseSuccessModal() {
    this.successModal = false
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY, HH:mm')
  }

  private isValidLatLong(lat?: number, long?: number): [boolean, boolean] {
    return Utils.validateIndonesiaCoordinate(lat, long)
  }

  private setAllHasChanged() {
    this.hasChanged = {
      subdistrictId: true,
      letterCode: true,
      address: true,
      phone: true,
      latitude: true,
      longitude: true,
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_POS_SUCCESS)
    this.controller.clearPOSDetail()
  }
}
