























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class PreviewOverlay extends Vue {
  @Prop({ default: false }) private visible!: boolean
  isVisible = this.visible

  @Watch('visible')
  onVisibleChanged(val: boolean): void {
    this.isVisible = val
  }

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private hideModal() {
    this.isVisible = false
    this.$emit('onClose', false)
  }
}
