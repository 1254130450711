






























import QrCreator from 'qr-creator'
import { Prop, Component, Vue } from 'vue-property-decorator'
import POSHeader from '@/app/ui/assets/pos_header.vue'

@Component({
  components: {
    POSHeader,
  },
})
export default class POSImage extends Vue {
  @Prop({ default: 'default' }) private qrStrings!: string
  @Prop({ default: '' }) private posId!: string | number
  @Prop({ default: '' }) private posName!: string
  @Prop({ default: '' }) private posAddress!: string

  epochNow = window.performance.now().toString()
  qrElementId = `qr-code-${this.epochNow}-${this.posId}`

  mounted(): void {
    QrCreator.render(
      {
        text: this.qrStrings,
        radius: 0.0, // 0.0 to 0.5
        ecLevel: 'H', // L, M, Q, H
        fill: '#fff', // foreground color
        background: null, // color or null for transparent
        size: 190, // in pixels
      },
      document.getElementById(this.qrElementId) || new HTMLElement()
    )

    setTimeout(() => {
      this.$emit('qrReady', true)
    }, 100) // Giving slight delay to assure render spare time
  }
}
